import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import styled from "styled-components";

const renderAside = (aside, i) => (
  <li className={aside.upcoming ? "upcoming" : null} key={i}>
    <h3><Link to={aside.link}>{aside.heading}</Link></h3>
    <p>{!aside.upcoming ? aside.description : "Coming soon!"}</p>
  </li>
);

const LinkBlockWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 0;
  li {
    flex: 0 2 320px;
    align-items: center;
    padding: 12px;
    margin: 0 1em 1.5em;
    list-style-type: none;
    cursor: default;
    h3 {
      margin-top: 0;
      font-size: 35px;
      padding: 25px 10px 10px;
    }
    p {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }
`;

class IndexPage extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  render() {
    let indexData = this.props.data.allIndexJson.nodes[0];
    return (
      <Layout 
        page={"home"}
        useSubheader={true}
        subheaderContent={{ title: indexData.title, subtitle: indexData.text }}
      >
        <div className="home-main">
          <LinkBlockWrapper>
            {indexData.bannerLinks.map(renderAside)}
          </LinkBlockWrapper>
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
        bannerLinks {
          heading
          description
          link
          upcoming
        }
      }
    }
  }
`
